import PbContentDate from "@/components/content/pbContentDate/pbContentDate";
import useScopedClassName from "@/hooks/useScopedClassName";
import useStrTypography from "@/hooks/useStrTypography";
import { ContentElementDateStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEDate } from "@/types/content-elements";
import { getPbContentScopedSelector } from "@/utils/util";
import clsx from "clsx";
import React from "react";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import DateContentElementStyleGlobal from "./dateContentElementStyleGlobal";
import DateContentElementStyleScoped from "./dateContentElementStyleScoped";
import { useAppSelector } from "@/store/store";
import { useTranslation } from "next-i18next";

export interface DateContentElementProps {
  content: CEDate;
  position: number;
  ceSettings?: ContentElementDateStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
  publishDate?: Date;
  publishedAt?: Date;
}

export const DATE_CE_NAME = "dateCE";
export default React.memo(function DateContentElement(
  props: DateContentElementProps
) {
  const DATE_SCOPED_CLASS = useScopedClassName(
    DATE_CE_NAME,
    props.content,
    props.position
  );
  const { t: tPublic } = useTranslation("public");
  const { typographyClassName } = useStrTypography(
    props.content.cfgStrTypography,
    props.ceSettings?.typographys
  );

  const publishDateEditView = useAppSelector(
    (state) => state.cmsEdit.data?.draftPage?.publishDate
  );
  const publishedAtEditView = useAppSelector(
    (state) => state.cmsEdit.data?.draftPage?.publishedAt
  );

  const publishDate = (publishDateEditView || props.publishDate) ?? (publishedAtEditView || props.publishedAt) ;

  const dateContent = props.content.cfgUsePublishingDate
    ? publishDate
    : props.content.displayedDate;

  const showDate =
    !props.content.cfgUsePublishingDate ||
    (props.content.cfgUsePublishingDate && publishDate);

  return (
    <>
      <ContentElementWrapper
        name={DATE_CE_NAME}
        scopedClassName={DATE_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        {showDate ? (
          <div className={clsx(DATE_CE_NAME, DATE_SCOPED_CLASS)}>
            <PbContentDate
              scopedSelector={getPbContentScopedSelector(DATE_SCOPED_CLASS)}
              dateAlignment={props.content.cfgDateInputAlign}
              cmsField={"displayedDate"}
              cmsPosition={props.position}
              content={dateContent}
              disableEditView={props.content.cfgUsePublishingDate}
              className={typographyClassName}
            />
          </div>
        ) : (
          <div className="not-published"> {tPublic("notYetPublished")} </div>
        )}
      </ContentElementWrapper>
      <DateContentElementStyleGlobal {...props} />
      <DateContentElementStyleScoped
        {...props}
        scopedSelector={DATE_SCOPED_CLASS}
      />
    </>
  );
});
