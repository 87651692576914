import { DateContentElementProps } from "./dateContentElement";

interface DateContentElementStyleScopedProps extends DateContentElementProps {
  scopedSelector: string;
}

/**
 * Scoped styles for the Date Content Element
 * Applied to individual instances of the Date element
 *
 * @param props
 * @returns
 */
export default function DateContentElementStyleScoped(
  props: DateContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
      }

      .not-published {
        text-align: center;
      }
    `}</style>
  );
}
