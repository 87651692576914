import PbDateInput from "@/components/input/pbDateInput/pbDateInput";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import clsx from "clsx";
import { useRouter } from "next/router";
import PbContentDateStyleGlobal from "./pbContentDateStyleGlobal";
import PbContentDateStyleScoped from "./pbContentDateStyleScoped";

export interface PbContentDateProps {
  cmsField: string;
  cmsPosition: number;
  disableEditView?: boolean;
  content: Date | string | undefined;
  dateAlignment?: string;
  cmsPlaceholder?: string;
  scopedSelector: string;
  className?: string;
}

const PbContentDate = (props: PbContentDateProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const handleDateChange = (newDate: string | null) => {
    const displayedDate = newDate || new Date().toISOString();
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsField}`,
        value: displayedDate,
      })
    );
  };

  return (
    <>
      <div
        className={clsx(
          "pb-content-date",
          props.scopedSelector,
          props.className
        )}
      >
        {editView && !props.disableEditView ? (
          <>
            <PbDateInput
              withCmsStyle
              value={props.content || new Date().toISOString()}
              onChange={handleDateChange}
            />
            <div
              className={clsx(
                "pb-content-date",
                props.dateAlignment && `text-${props.dateAlignment}`
              )}
            >
              {props.content
                ? new Date(props.content).toLocaleDateString(router.locale)
                : new Date().toLocaleDateString(router.locale)}
            </div>
          </>
        ) : (
          <div
            className={clsx(
              "pb-content-date",
              props.dateAlignment && `text-${props.dateAlignment}`
            )}
          >
            {props.content
              ? new Date(props.content).toLocaleDateString(router.locale)
              : new Date().toLocaleDateString(router.locale)}
          </div>
        )}
      </div>
      <PbContentDateStyleGlobal />
      <PbContentDateStyleScoped {...props} />
    </>
  );
};

export default PbContentDate;
