import { DATE_CE_NAME, DateContentElementProps } from "./dateContentElement";

/**
 * This is the global style of a CE.
 * This style is applied on all instances of this CE
 *
 * @param props
 * @returns
 */
export default function DateContentElementStyleGlobal(
  props: DateContentElementProps
) {
  return (
    <style jsx global>
      {`
        :global(.${DATE_CE_NAME}) {
          padding: 0.5rem;
        }
      `}
    </style>
  );
}
