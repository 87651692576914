import { PbContentDateProps } from "@/components/content/pbContentDate/pbContentDate";

interface PbContentDateStyleScopedProps extends PbContentDateProps {
  scopedSelector: string;
}

/**
 *
 * @param props
 * @returns
 */
export default function PbContentDateStyleScoped(
  props: PbContentDateStyleScopedProps
) {
  const textAlign = props.dateAlignment ?? "center";
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        text-align: ${textAlign};
      }
    `}</style>
  );
}
