export default function PbContentDateStyleGlobal() {
  return (
    <style jsx global>{`
      .pb-content-date {
        background-color: var(--pb-cms-transparent);
        width: 100%;
        border: none;
        display: block;
        word-break: break-word;

        .MuiOutlinedInput-root {
          .MuiInputBase-input {
            height: 22px;
            margin-left: 3px;
          }
        }
      }

      .pb-content-date:focus {
        outline: none;
      }

      .pb-content-date input {
        background-color: var(--pb-cms-color);
        border: none;
        padding: 0.5rem;
        border-radius: 4px;
        width: 100%;
      }

      .pb-content-date input:focus {
        border-color: #007bff;
      }
    `}</style>
  );
}
